import React, { useState, useEffect } from 'react';
import './styles/login.css';
import { Link, useNavigate } from 'react-router-dom';
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import { useDispatch, useSelector } from 'react-redux';
import { userLoginAndAuthentication } from '../redux/actions/userAction';

function Login() {
    const [showPassword, setShowPassword] = useState(false);
    const [formData, setFormData] = useState({ username: '', password: '' });

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const { loading: loadingDetails, error: errorDetails, errorTesting: errorDetailsTesting, auth: isAuth, token: authToken } = useSelector(state => state.user);

    // Navigate when authentication succeeds
    useEffect(() => {
        const auth = isAuth || localStorage.getItem('auth');
        const userCompanie = isAuth || localStorage.getItem('userCompanie');
        if (auth) {
            navigate('/');
        }
    }, [isAuth, authToken, navigate, loadingDetails]);



    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        dispatch(userLoginAndAuthentication(formData.username, formData.password));

    };

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    return (
        <div className="login-screen-container">
            <div className="d-flex justify-content-center align-items-center mt-4">
                <div className="d-flex justify-content-center align-items-center w-100">
                    <div className="login-form">
                        <form className="login-form" onSubmit={handleSubmit}>
                            <h2 className="my-4" style={{ userSelect: "none" }}>Sign in</h2>

                            <div className="form-data mt-5">
                                <div className="form-group">
                                    <input
                                        className="input-Box"
                                        type="text"
                                        id="username"
                                        name="username"
                                        value={formData.username}
                                        onChange={handleChange}
                                        required
                                        placeholder="Username"
                                    />
                                </div>
                                <div className="form-group password-group">
                                    <input
                                        className="input-Box"
                                        type={showPassword ? 'text' : 'password'}
                                        id="password"
                                        name="password"
                                        value={formData.password}
                                        onChange={handleChange}
                                        required
                                        placeholder="Password"
                                    />
                                    <span className="eye-icon" onClick={togglePasswordVisibility}>
                                        {showPassword ? <FaEyeSlash /> : <FaEye />}
                                    </span>
                                </div>
                            </div>

                            {/* Error message */}
                            {errorDetailsTesting && <p className="error-message text-danger">Invalid Username or Password</p>}

                            <button type="submit" className="login-button" disabled={loadingDetails}>
                                {loadingDetails ? 'Signing in...' : 'Sign in'}
                            </button>

                            <div className="mt-5">
                                <div className="d-flex justify-content-center align-items-center">
                                    <h6>Not an account yet?</h6>
                                    <Link to="/register"><h6 className="mx-2">Register</h6></Link>
                                </div>
                                <div className="d-flex justify-content-center align-items-center">
                                    <Link to="/password-reset"><h6>Forgot your Password?</h6></Link>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Login;
