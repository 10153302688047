import axios from 'axios';
import { fetchItemDetailsFailure } from './itemActions';

export const USER_REGISTER_REQUEST = 'USER_REGISTER_REQUEST';
export const USER_REGISTER_SUCCESS = 'USER_REGISTER_SUCCESS';
export const USER_REGISTER_FAILURE = 'USER_REGISTER_FAILURE';

export const USER_LOGIN_REQUEST = 'USER_LOGIN_REQUEST';
export const USER_LOGIN_SUCCESS = 'USER_LOGIN_SUCCESS';
export const USER_LOGIN_FAILURE = 'USER_LOGIN_FAILURE';
export const USER_LOGIN_FAILURE_TESTING = 'USER_LOGIN_FAILURE_TESTING';


export const USER_ROLES_REQUEST = 'USER_ROLES_REQUEST';
export const USER_ROLES_SUCCESS = 'USER_ROLES_SUCCESS';
export const USER_ROLES_FAILURE = 'USER_ROLES_FAILURE';

export const USERS_BY_ROLES_REQUEST = 'USERS_BY_ROLES_REQUEST';
export const USERS_BY_ROLES_SUCCESS = 'USERS_BY_ROLES_SUCCESS';
export const USERS_BY_ROLES_FAILURE = 'USERS_BY_ROLES_FAILURE';

export const FETCH_ALL_REGISTERD_USERS_REQUEST = 'FETCH_ALL_REGISTERD_USERS_REQUEST'
export const FETCH_ALL_REGISTERD_USERS_SUCCESS = 'FETCH_ALL_REGISTERD_USERS_SUCCESS'
export const FETCH_ALL_REGISTERD_USERS_FAILURE = 'FETCH_ALL_REGISTERD_USERS_FAILURE'

export const USER_UPDATE_REQUEST = 'USER_UPDATE_REQUEST'
export const USER_UPDATE_SUCCESS = 'USER_UPDATE_SUCCESS'
export const USER_UPDATE_FAILURE = 'USER_UPDATE_FAILURE'

export const USER_DELETE_REQUEST = 'USER_DELETE_REQUEST'
export const USER_DELETE_SUCCESS = 'USER_DELETE_SUCCESS'
export const USER_DELETE_FAILURE = 'USER_DELETE_FAILURE'


//users by user roles

export const usersByRolesRequest = () => {
    return {
        type: USERS_BY_ROLES_REQUEST,
    }
}

export const usersByRolesSuccess = (usersByRoles) => {
    return {
        type: USERS_BY_ROLES_SUCCESS,
        payload: usersByRoles,
    }
}

export const usersByRolesFailure = (error) => {
    return {
        type: USERS_BY_ROLES_FAILURE,
        payload: error,
    }
}

//user role management

export const userRoleRequest = () => {
    return {
        type: USER_ROLES_REQUEST,
    }
}

export const userRoleSuccess = (roles) => {
    return {
        type: USER_ROLES_SUCCESS,
        payload: roles,
    }
}

export const userRoleFailure = (error) => {
    return {
        type: USER_ROLES_FAILURE,
        payload: error,
    }
}

//user register

export const userRegisterRequest = () => {
    return {
        type: USER_REGISTER_REQUEST,
    }
}

export const userRegisterSuccess = (user) => {
    return {
        type: USER_REGISTER_SUCCESS,
        payload: user,
    }
}

export const userRegisterFailure = (error) => {
    return {
        type: USER_REGISTER_FAILURE,
        payload: error,
    }
}

//user Login

export const userLoginRequest = () => {
    return {
        type: USER_LOGIN_REQUEST,
    }
}

export const fetchAllRegisterdUsersRequest = () => {
    return {
        type: FETCH_ALL_REGISTERD_USERS_REQUEST
    }
}

export const fetchAllRegisterdUsersSuccess = (allRegisterdUsers) => {
    return {
        type: FETCH_ALL_REGISTERD_USERS_SUCCESS,
        payload: allRegisterdUsers
    }
}

export const fetchAllRegisterdUsersFailure = (error) => {
    return {
        type: FETCH_ALL_REGISTERD_USERS_FAILURE,
        payload: error
    }
}


export const userLoginSuccess = async (user, token) => {
    console.log('user',user);
    
    const loginTime = Date.now();
    localStorage.setItem('AuthToken', token);
    localStorage.setItem('loggedUserName', user.first_name);
    localStorage.setItem('auth', true);
    localStorage.setItem('loggedUserName', user.first_name);
    localStorage.setItem('user_role', 'Admin');
    localStorage.setItem('user_id', user.id);
    localStorage.setItem('loggedDate',loginTime)
    // localStorage.setItem('userCompanie', user.selectedCompany?.id);
    // localStorage.setItem("selectedCompany", JSON.stringify({ value: user.selectedCompany.id, label: user.selectedCompany.company_name }));
    // localStorage.setItem("user_companies", JSON.stringify(user.company_registration_ids));
    return {
        type: USER_LOGIN_SUCCESS,
        payload: {
            user: user,
            token: token,
            login: true
        },
    }
}

export const userLoginFailure = (error) => {

    return {
        type: USER_LOGIN_FAILURE,
        payload: error,
    }
}

//this use for ignore user login id type error in the front end but correct method is it is solevd from backend
export const userLoginFailureTesting = (error) => {

    return {
        type: USER_LOGIN_FAILURE_TESTING,
        payload: error,
    }
}

export const updateUserDetailsRequest = (error) => {
    return {
        type: USER_DELETE_REQUEST,
        payload: error,
    }
}

export const updateUserDetailsSuccess = (user, error) => {
    return {
        type: USER_DELETE_SUCCESS,
        payload: user,
    }
}

export const updateUserFailure = (error) => {
    return {
        type: USER_DELETE_FAILURE,
        payload: error,
    }
}

export const deleteUserDetailsRequest = (error) => {
    return {
        type: USER_DELETE_REQUEST,
        payload: error,
    }
}

export const deleteUserDetailsSuccess = (pk, error) => {
    return {
        type: USER_DELETE_SUCCESS,
    }
}

export const deleteUserFailure = (error) => {
    return {
        type: USER_DELETE_FAILURE,
        payload: error,
    }
}

//save new user

function getRoleNameByRoleId(roleId) {
    console.log(roleId);
    return async (roleId) => {
        try {
            const responce = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/role/${roleId}`);
        } catch (error) {

        }
    }
}

export const saveNewUser = (user) => {
    return async (dispatch) => {
        dispatch(userRegisterRequest(user))
        try {
            const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/auth-register/`, user);
            dispatch(userRegisterSuccess(response.data));
        } catch (error) {
            dispatch(userRegisterFailure(error.response?.data?.message || error.message))
        }
    }
}

//user login

export const saveSessionData = async (user) => {
    console.log('passing user', user);
    try {
        const customers = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/companies/`);
        console.log('customers', customers)
        const selectedCompany = customers.data?.find(company => company.id === user.company_registration_ids[0] || user.company_registration_id[0]);
        console.log('selectedCompany', selectedCompany)
        if (selectedCompany) {
            localStorage.setItem('userCompanie', selectedCompany.id);
            localStorage.setItem("selectedCompany", JSON.stringify({ value: selectedCompany.id, label: selectedCompany.company_name }));
            localStorage.setItem("user_companies", JSON.stringify(user.company_registration_id||user.company_registration_ids));
        } else {
            console.error("Company not found for the user");
            
        }
        
        window.location.reload();
    } catch (error) {
        console.error("Error fetching company data:", error);
    }
};

export const userLoginAndAuthentication = (username, password) => {
    return async (dispatch) => {
        dispatch(userLoginRequest(username, password))
        try {
            const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/auth-login/`,  { username, password });
            console.log('response', response);
            const { user, access } = response.data;
            await saveSessionData(user);
            dispatch(userLoginSuccess(user, access));
        } catch (error) {
            dispatch(userLoginFailureTesting(error.response?.data?.message || error.message))
        }
    }
}

// export const userLoginAndAuthentication = (username, password) => {
//     let acccessToken;
//     return async (dispatch) => {
//         dispatch(userLoginRequest(username, password))
//         try {
//             // Include the username in the URL
//             const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/user/login_by_user_name/${username}/`, { username });
//             const { user } = response.data;
//             await saveSessionData(user);
//             acccessToken = "access";
//             dispatch(userLoginSuccess(user, acccessToken));
//         } catch (error) {
//             dispatch(userLoginFailureTesting(error.response?.data?.message || error.message))
//         }
//     }
// }

//fetch user roles

export const fetchAllUserRoles = () => {
    return async (dispatch) => {
        dispatch(userRoleRequest())
        try {
            const responce = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/user-roles/`);
            dispatch(userRoleSuccess(responce.data.roles));
        } catch (error) {
            dispatch(userRoleFailure(error));
        }
    }
}

// Fetch users by role ID
export const getUsersByRoleId = (roleId) => {
    return async (dispatch) => {
        dispatch(usersByRolesRequest());
        try {
            const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/users-by-role/${roleId}/`);
            dispatch(usersByRolesSuccess(response.data));
        } catch (error) {
            dispatch(usersByRolesFailure(error.response?.data?.message || error.message));
        }
    };
};


export const fetchAllUsersRegisterd = () => {
    return async (dispatch) => {
        dispatch(fetchAllRegisterdUsersRequest)
        try {
            const responce = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/all-custom-users/`)
            dispatch(fetchAllRegisterdUsersSuccess(responce.data.users))
        } catch (error) {
            dispatch(fetchAllRegisterdUsersFailure(error))
        }
    }
}

export const updateUserByUserId = (pk, user) => {
    console.log('user request', user);
    return async (dispatch) => {
        dispatch(updateUserDetailsRequest(user));
        try {
            const responce = await axios.put(`${process.env.REACT_APP_BACKEND_URL}/user/update/${pk}/`, user);
            console.log('responce update user', responce);
            dispatch(updateUserDetailsSuccess(responce.data))
        } catch (error) {
            dispatch(updateUserFailure(error));
        }
    }
}

export const deleteUserByUserId = (pk) => {
    return async (dispatch) => {
        dispatch(deleteUserDetailsRequest());
        try {
            const responce = await axios.delete(`${process.env.REACT_APP_BACKEND_URL}/user/delete/${pk}/`);
            console.log('responce update user', responce);
            dispatch(deleteUserDetailsSuccess(responce.data))
        } catch (error) {
            dispatch(deleteUserFailure(error));
        }
    }
}


