// hiding nav bar
import './NavigationBar.css';
import { useState, useEffect, useRef } from 'react';
import logo from '../assest/logog.png';
import { GiHamburgerMenu } from 'react-icons/gi';
import { IoClose, IoLogOut } from 'react-icons/io5';
import { Link, useNavigate } from 'react-router-dom';
import { IoMdShareAlt } from "react-icons/io";
import { AiOutlineLogin } from 'react-icons/ai';
import { FaChartLine, FaLocationArrow, FaPlane, FaSeedling, FaStethoscope, FaUserCircle } from 'react-icons/fa';
import { useSelector } from 'react-redux';
import { Button } from 'react-bootstrap';
import { GrCycle } from 'react-icons/gr';

function Navigationbar() {
    const [isOpen, setIsOpen] = useState(false);
    const sidebarRef = useRef(null);
    const sharePageRef = useRef(null);
    const [isShowSharePage, setIsShowSharePage] = useState(false);
    const [isCopyUrl, setIsCopyUrl] = useState(false);

    // Get user details from Redux
    const { loading: loadingDetails, user = {}, userName: loggedUserName, error: errorDetails, token: authToken, auth: isauth } = useSelector(state => state.user);
    const navigate = useNavigate();

    const userRole = localStorage.getItem('user_role') || 'Guest'; // Fallback role if not defined
    const loggedUserUsername = localStorage.getItem('loggedUserName');

    const toggleNavbar = () => {
        setIsOpen(!isOpen);
    };

    const closeSidebar = () => {
        setIsOpen(false);
    };

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (sidebarRef.current && !sidebarRef.current.contains(event.target)) {
                setIsOpen(false);
            }
            if (sharePageRef.current && !sharePageRef.current.contains(event.target)) {
                setIsShowSharePage(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [sidebarRef, sharePageRef]);

    let url = window.location.href;

    const sharePage = () => {
        setIsShowSharePage(!isShowSharePage);
    };

    const copyLink = () => {
        navigator.clipboard.writeText(url).then(() => {
            setIsCopyUrl(true);
            setTimeout(() => setIsCopyUrl(false), 2000);
        });
    };

    const navigateToProductLifeCycle = () => {
        window.location.href = `https://greenbless.com/product/#/home`;
        // window.location.href = `http://localhost:3002/product/#/home`;
        // window.location.href = `http://192.168.1.14:3001/product/#/home`;
    };

    const navigateToTravelService = () => {
        window.location.href = `https://greenbless.com/travel/#/`;
        // window.location.href = `http://localhost:3001/product/#/home`;
        // window.location.href = `http://192.168.1.14:3001/product/#/home`;
    };


    const userLogout = () => {
        localStorage.removeItem('AuthToken');
        localStorage.removeItem('loggedUserName');
        localStorage.removeItem('auth');
        localStorage.removeItem('loggedUserName');
        localStorage.removeItem('user_role');
        localStorage.removeItem('user_id');
        localStorage.removeItem('userCompanie');
        localStorage.removeItem("selectedCompany");
        localStorage.removeItem("user_companies");
        setTimeout(() => window.location.reload(), 500);
        navigate('/register');
    };

    // Role-based navigation l
    const roleRoutes = {
        Admin: [
            { path: "/productLifeCycle", label: "Product LifeCycle", icon: <GrCycle />, onClick: navigateToProductLifeCycle },
            // { path: "/docApp", label: "HealthCare Services", icon: <FaStethoscope /> },
            // { path: "/travel", label: "Travel", icon: <FaLocationArrow />, onClick: navigateToTravelService },
            { path: "/AgroTechHome", label: "Agro Tech", icon: <FaSeedling /> },
            // { path: "/rollingHme", label: "Rolling Forecast", icon: <FaChartLine /> },
        ],
        Farmer: [
            { path: "/productLifeCycle", label: "Product LifeCycle", icon: <GrCycle />, onClick: navigateToProductLifeCycle },
            { path: "/AgroTechHome", label: "Agro Tech", icon: <FaSeedling /> },
        ],
        Doctor: [
            // { path: "/docApp", label: "HealthCare Services", icon: <FaStethoscope /> },
        ],
        Nurse: [
            // { path: "/docApp", label: "HealthCare Services", icon: <FaStethoscope /> },
        ],
        Helpdesk_operator: [
            // { path: "/docApp", label: "HealthCare Services", icon: <FaStethoscope /> },
        ],
        Guest: [
            // { path: "/productLifeCycle", label: "Product LifeCycle", icon: <GrCycle />, onClick: navigateToProductLifeCycle },
        ], // Default role with no access
    };

    const routes = roleRoutes[userRole] || [];

    return (
        <>
            <div className="navbar-container">
                <div className="d-flex justify-content-between align-item-center">
                    <div>
                        <a href='/'><img src={logo} width={210} alt="logo" /></a>
                    </div>
                    <div>
                        <button onClick={sharePage} className="toggle-button">
                            <IoMdShareAlt className="icon-nav-menu" />
                        </button>
                        <button onClick={toggleNavbar} className="toggle-button">
                            {isOpen ? <IoClose className="icon-nav-close" />
                                : <GiHamburgerMenu className="icon-nav-menu" />}
                        </button>
                    </div>
                </div>
                <div ref={sidebarRef} className={`nav-links ${isOpen ? 'open' : ''}`}>
                    {isauth ? (
                        <div className="d-flex align-items-center justify-content-between">
                            <div className="d-flex align-items-center">
                                <FaUserCircle size={30} className="me-2 text-primary text-white" />
                                <span className="fw-bold">{loggedUserUsername}</span>
                            </div>
                            <div>
                                <IoLogOut style={{ cursor: 'pointer' }} onClick={userLogout} size={30} className="me-2 text-primary text-white" />
                            </div>
                        </div>
                    ) : (
                        <Button variant="success" size="lg" className="d-flex align-items-center px-4" onClick={() => navigate('/login')}>
                            <AiOutlineLogin size={20} className="me-2" style={{ color: 'white' }} />
                            Login/Register
                        </Button>
                    )}
                    <hr />
                    {routes.map((route, index) => (
                        <div key={index}>
                            {route.onClick ? (
                                <div onClick={route.onClick} style={{cursor:"pointer"}}>
                                    <h6>{route.icon} {route.label}</h6>
                                </div>
                            ) : (
                                <Link to={route.path} onClick={closeSidebar}>
                                    <h6>{route.icon} {route.label}</h6>
                                </Link>
                            )}
                        </div>
                    ))}
                    {/* <Link to="https://rnrobots.com/product/" onClick={()=>{}}><h6><GrCycle className='mx-2' /> Product LifeCycle </h6></Link> */}
                    {/* <div onClick={() => {navigateToProductLifeCycle()}}><h6><GrCycle className='mx-2' /> Product LifeCycle</h6></div> */}
                </div>
            </div>

            {isShowSharePage && (
                <div ref={sharePageRef}>
                    <div className="share-box">
                        <h3>Share this page</h3>
                        <div className="share-options">
                            <a
                                href={`https://wa.me/?text=Check%20this%20out:%20${encodeURIComponent(url)}`}
                                target="_blank"
                                className="share-option mx-2"
                                rel="noopener noreferrer"
                            >
                                <img src="https://img.icons8.com/color/48/000000/whatsapp.png" alt="WhatsApp" />
                                <span>WhatsApp</span>
                            </a>
                            <a
                                href={`https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(url)}`}
                                target="_blank"
                                className="share-option mx-2"
                                rel="noopener noreferrer"
                            >
                                <img src="https://img.icons8.com/color/48/000000/facebook-new.png" alt="Facebook" />
                                <span>Facebook</span>
                            </a>
                            <a
                                href={`https://www.messenger.com/t?link=${encodeURIComponent(url)}`}
                                target="_blank"
                                className="share-option mx-2"
                                rel="noopener noreferrer"
                            >
                                <img src="https://img.icons8.com/color/48/000000/facebook-messenger.png" alt="Messenger" />
                                <span>Messenger</span>
                            </a>
                            <div className="share-option" onClick={copyLink}>
                                <img src="https://img.icons8.com/color/48/000000/copy.png" alt="Copy Link" />
                                <span>Copy Link            </span>
                            </div>
                        </div>
                        <input
                            type="text"
                            className={isCopyUrl ? "copyedUrl" : "share-url"}
                            id="shareUrl"
                            value={url}
                            readOnly
                        />
                    </div>
                </div>
            )}
        </>
    );
}

export default Navigationbar;


// export default Navigationbar;
